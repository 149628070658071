@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Cinzel:wght@400..900&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inria Serif', serif;
}

#hero-banner {
  background-image: url(assets/picture/hero-banner.png);
  background-size: cover;
  background-position: center top;
}

#meisa-banner {
  background-image: url(assets/picture/meisa-banner.png);
  /* background-size: auto; */
  background-position: center;
}

#gallery-banner {
  background-image: url(assets/picture/gallery-banner.png);
  background-size: cover;
  background-position: center;
}

#experience-banner {
  background-image: url(assets/picture/experience-banner.png);
  background-size: cover;
  background-position: center;
}

#demography-banner {
  background-image: url(assets/picture/demography-banner.png);
  background-size: cover;
  background-position: center;
}


#pattern-bg{
  background-image: url(assets/picture/pattern-profile.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

#gallery-bg{
  background-image: url(assets/picture/gallery-pattern.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -5%;
}

#gallery-bg-page{
  background-image: url(assets/picture/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

#about-bg-page{
  background-image: url(assets/picture/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

#experience-page-bg{
  background-image: url(assets/picture/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

#demo-bg-page{
  background-image: url(assets/picture/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
